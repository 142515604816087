import { AppEnvironment, ExternalRouteInfo, FdRouteInfo, FdRouteInfoData, Primitives } from "@fd/core";
import { environment } from "src/environments/environment";
import { UserRoles } from "./modules/shared/enums/user-roles.enum";

export class RouteInfo<PathParams extends Primitives[] = [], QueryParams extends {} = {}> extends FdRouteInfo<
  UserRoles,
  PathParams,
  QueryParams
> {}

export class AppRoutes {
  public static readonly root = new RouteInfo(null, "", { title: "", allowAnonymous: true });

  public static readonly login = new RouteInfo(AppRoutes.root, "login", {
    title: "Login",
    showInMenu: false,
    allowAnonymous: true,
  });
  public static readonly home = new RouteInfo(AppRoutes.root, "home", { title: "Home", showInMenu: false });
  public static readonly notAuthorized = new RouteInfo(AppRoutes.root, "not-authorized", {
    title: "Not Authorized",
    showInMenu: false,
    allowAnonymous: true,
  });

  public static insights = new RouteInfo(AppRoutes.root, "insights", {
    title: "Insights",
    roleView: UserRoles.Insights_Download_View,
  });

  // #region Admin Module
  public static adminRoot = new RouteInfo(AppRoutes.root, "admin", {
    title: "Admin",
    roleArea: UserRoles.Admin,
  });
  public static admin = {
    users: new RouteInfo(AppRoutes.adminRoot, "users", {
      title: "Users",
      roleView: UserRoles.Admin_Users_View,
      additionalRoles: [
        UserRoles.Admin_Users_Edit,
        UserRoles.Admin_ImpersonateUser,
        UserRoles.Admin_ViewAllCustomerData,
      ],
    }),
    securityGroups: new RouteInfo(AppRoutes.adminRoot, "security-groups", {
      title: "Security Groups",
      roleView: UserRoles.Admin_SecurityGroups_View,
      additionalRoles: [UserRoles.Admin_SecurityGroups_Edit],
    }),
    customerGroups: new RouteInfo(AppRoutes.adminRoot, "customer-groups", {
      title: "Groups",
      roleView: UserRoles.Admin_CustomerGroups_View,
      additionalRoles: [UserRoles.Admin_CustomerGroups_Edit, UserRoles.Admin_ViewAllCustomerData],
    }),
    plants: new RouteInfo(AppRoutes.adminRoot, "plants", {
      title: "Plants",
      roleView: UserRoles.Admin_Plants_View,
      additionalRoles: [UserRoles.Admin_Plants_Edit, UserRoles.Admin_ViewAllCustomerData],
    }),
    casters: new RouteInfo(AppRoutes.adminRoot, "casters", {
      title: "Casters",
      roleView: UserRoles.Admin_Casters_View,
      additionalRoles: [
        UserRoles.Admin_Casters_Edit,
        UserRoles.Admin_Casters_TruncateDate,
        UserRoles.Admin_Casters_ConfigureDatabase,
        UserRoles.Admin_ViewAllCustomerData,
      ],
    }),
    metrics: new RouteInfo<["caster" | "global"]>(AppRoutes.adminRoot, "metrics/:?view", {
      title: "Metrics",
      roleView: UserRoles.Admin_Metrics_View,
      additionalRoles: [UserRoles.Admin_Metrics_Edit, UserRoles.Admin_ManageGlobalData],
      defaultPathParams: ["caster"],
    }),
    metricLiveData: new RouteInfo<["metrics" | "tags"]>(AppRoutes.adminRoot, "metric-live-data/:?view", {
      title: "Metrics Live View",
      roleView: UserRoles.Admin_MetricLiveData_View,
      defaultPathParams: ["metrics"],
    }),
    gradeFamilies: new RouteInfo(AppRoutes.adminRoot, "grade-families", {
      title: "Grade Families",
      roleView: UserRoles.Admin_GradeFamilies_View,
      additionalRoles: [UserRoles.Admin_GradeFamilies_Edit],
    }),
    dataFetcher: new RouteInfo(AppRoutes.adminRoot, "data-fetcher", {
      title: "Data Fetcher",
      roleView: UserRoles.Admin_DataFetcher_View,
      additionalRoles: [UserRoles.Admin_DataFetcher_Edit],
    }),
    ipAddressWhitelist: new RouteInfo(AppRoutes.adminRoot, "ip-address-whitelist", {
      title: "IP Address Whitelisting",
      roleView: UserRoles.Admin_IPAddressWhitelist_View,
      additionalRoles: [UserRoles.Admin_IPAddressWhitelist_Edit],
    }),
  };
  // #endregion

  // #region Account Module
  public static accountRoot = new RouteInfo(null, "account", {
    title: "Account",
    showInMenu: false,
    allowAnonymous: true,
  });
  public static account = {
    registration: new RouteInfo(AppRoutes.accountRoot, "registration", {
      title: "Registration",
      allowAnonymous: true,
    }),
    externalLoginCallback: new RouteInfo(AppRoutes.accountRoot, "external-login-callback", {
      title: "Logging you in...",
      allowAnonymous: true,
    }),
    confirmEmail: new RouteInfo(AppRoutes.accountRoot, "confirm-email", {
      title: "Confirm Email",
      allowAnonymous: true,
    }),
    forgotPassword: new RouteInfo(AppRoutes.accountRoot, "forgot-password", {
      title: "Forgot Password",
      allowAnonymous: true,
    }),
    resetPassword: new RouteInfo(AppRoutes.accountRoot, "reset-password", {
      title: "Reset Password",
      allowAnonymous: true,
    }),
    profile: new RouteInfo(AppRoutes.accountRoot, "profile", { title: "Profile" }),
    userPreferences: new RouteInfo(AppRoutes.accountRoot, "user-preferences", { title: "User Preferences" }),
  };
  // #endregion

  // #region Aggregation Module

  public static aggregationRoot = new RouteInfo(AppRoutes.root, "aggregation", {
    title: "Aggregation",
    roleArea: UserRoles.Aggregation,
  });
  public static aggregation = {
    casterMetricHistory: new RouteInfo(AppRoutes.aggregationRoot, "caster-metric-history", {
      title: "Metric History",
      roleView: UserRoles.Aggregation_CasterMetricHistory_View,
      additionalRoles: [UserRoles.Aggregation_CasterMetricHistory_Edit],
    }),
    moldSizeSettings: new RouteInfo(AppRoutes.aggregationRoot, "mold-size-settings", {
      title: "Mold Size Settings",
      roleView: UserRoles.Aggregation_MoldSizeSetting_View,
      additionalRoles: [UserRoles.Aggregation_MoldSizeSetting_Edit],
    }),
  };
  // #endregion

  // #region Dashboard Module
  public static dashboardRoot = new RouteInfo(AppRoutes.root, "dashboard", {
    title: "Dashboard",
    roleArea: UserRoles.Dashboard,
  });
  public static dashboard = {
    caster: new RouteInfo(AppRoutes.dashboardRoot, "caster", {
      title: "Caster Dashboard",
      roleView: UserRoles.Dashboard_Caster_View,
    }),
    dial: new RouteInfo(AppRoutes.dashboardRoot, "dial", {
      title: "Dial Dashboard",
      roleView: UserRoles.Dashboard_Dial_View,
    }),
  };
  // #endregion

  // #region Dashboard Module
  public static eventRoot = new RouteInfo(AppRoutes.root, "event", {
    title: "Event",
    roleArea: UserRoles.Event,
  });
  public static event = {
    reasons: new RouteInfo(AppRoutes.eventRoot, "reasons", {
      title: "Reasons",
      roleView: UserRoles.Event_Reasons_View,
      additionalRoles: [UserRoles.Event_Reasons_Edit],
    }),
    shifts: new RouteInfo(AppRoutes.eventRoot, "shifts", {
      title: "Shifts",
      roleView: UserRoles.Event_Shifts_View,
      additionalRoles: [UserRoles.Event_Shifts_Edit],
    }),
    casterEvents: new RouteInfo(AppRoutes.eventRoot, "caster-events", {
      title: "Caster Events",
      roleView: UserRoles.Event_CasterEvents_View,
      additionalRoles: [UserRoles.Event_CasterEvents_Edit],
    }),
  };
  // #endregion

  // #region Framework Module
  public static frameworkRoot = new RouteInfo(AppRoutes.root, "framework", {
    title: "Framework",
    showInMenu: environment.configuration !== AppEnvironment.Production,
  });
  public static frameworkSwaggerRoot = new ExternalRouteInfo(AppRoutes.frameworkRoot, "Swagger", "/swagger", {
    showInMenu: environment.configuration === AppEnvironment.Development,
    target: "swagger",
  });
  public static frameworkHealthRoot = new ExternalRouteInfo(
    AppRoutes.frameworkRoot,
    "Health Check",
    "/healthchecks-ui",
    {
      showInMenu: environment.configuration === AppEnvironment.Development,
      target: "healthchecks",
    }
  );
  public static frameworkRinRoot = new ExternalRouteInfo(
    AppRoutes.frameworkRoot,
    "Request Inspector (Rin)",
    `${environment.localServerUrl}/rin`,
    {
      showInMenu: environment.configuration === AppEnvironment.Development,
      target: "rin",
    }
  );
  public static frameworkCoreRoot = new RouteInfo(AppRoutes.frameworkRoot, "core", {
    title: "Core",
    isNestedRoot: true,
  });
  public static frameworkControlsRoot = new RouteInfo(AppRoutes.frameworkRoot, "controls", {
    title: "Form Controls",
    isNestedRoot: true,
  });
  public static frameworkWidgetsRoot = new RouteInfo(AppRoutes.frameworkRoot, "widgets", {
    title: "Widgets",
    isNestedRoot: true,
  });
  public static frameworkLayoutsRoot = new RouteInfo(AppRoutes.frameworkRoot, "layouts", {
    title: "Layouts",
    isNestedRoot: true,
  });
  public static framework = {
    // core
    about: new RouteInfo(AppRoutes.frameworkCoreRoot, "about", { title: "About" }),
    cache: new RouteInfo<["server" | "client"]>(AppRoutes.frameworkCoreRoot, "cache/:?view", {
      title: "Cache",
      defaultPathParams: ["server"],
    }),

    dateTime: new RouteInfo(AppRoutes.frameworkCoreRoot, "date-time", { title: "Date & Time" }),
    email: new RouteInfo(AppRoutes.frameworkCoreRoot, "email", { title: "Email" }),
    errorHandling: new RouteInfo(AppRoutes.frameworkCoreRoot, "error-handling", { title: "Error Handling" }),
    files: new RouteInfo<["ico" | "csv" | "xlsx" | "pdf"]>(AppRoutes.frameworkCoreRoot, "download-file/:?fileType", {
      title: "Files",
      defaultPathParams: ["ico"],
    }),
    form: new RouteInfo(AppRoutes.frameworkCoreRoot, "form", { title: "Form" }),
    globalLoadingIndicator: new RouteInfo(AppRoutes.frameworkCoreRoot, "global-loading-indicator", {
      title: "Global Loading Indicator",
    }),
    invalidModelState: new RouteInfo(AppRoutes.frameworkCoreRoot, "invalid-model-state", {
      title: "Invalid Model State ",
    }),
    pipes: new RouteInfo(AppRoutes.frameworkCoreRoot, "pipes", { title: "Pipes" }),
    prototyping: new RouteInfo(AppRoutes.frameworkCoreRoot, "prototyping", { title: "Prototyping" }),
    routing: new RouteInfo<[number, string, boolean?], { age?: number; grade?: string }>(
      AppRoutes.frameworkCoreRoot,
      "routing/:id/:name/:?flag",
      {
        title: "Routing",
        defaultPathParams: [0, "_"],
        defaultQueryParams: { age: 0 },
      }
    ),

    // form controls
    autocomplete: new RouteInfo(AppRoutes.frameworkControlsRoot, "autocomplete", { title: "Autocomplete" }),
    checkbox: new RouteInfo(AppRoutes.frameworkControlsRoot, "checkbox", { title: "Checkbox" }),
    datepicker: new RouteInfo(AppRoutes.frameworkControlsRoot, "datepicker", { title: "Date / Time Picker" }),
    number: new RouteInfo(AppRoutes.frameworkControlsRoot, "number", { title: "Number" }),
    select: new RouteInfo(AppRoutes.frameworkControlsRoot, "select", { title: "Select" }),
    text: new RouteInfo(AppRoutes.frameworkControlsRoot, "text", { title: "Text" }),
    toggle: new RouteInfo(AppRoutes.frameworkControlsRoot, "toggle", { title: "Toggle" }),
    uploadFile: new RouteInfo(AppRoutes.frameworkControlsRoot, "upload-file", { title: "Upload File" }),

    // widgets
    button: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "button", { title: "Button" }),
    chart: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "chart", { title: "Chart" }),
    confirmationPopover: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "confirmation-popover", {
      title: "Confirmation Popover",
    }),
    dataTables: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "data-tables", { title: "Data Tables" }),
    dialog: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "dialog", { title: "Dialog" }),
    menu: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "menu", { title: "Menu" }),
    snackBar: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "snack-bar", { title: "Snack Bar" }),
    tooltip: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "tooltip", { title: "Tooltip" }),

    // layouts
    layoutCards: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "cards/:?view", {
      title: "Cards",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutTableEditable: new RouteInfo(AppRoutes.frameworkLayoutsRoot, "table-editable", {
      title: "Editable Table",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutReport: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "report/:?view", {
      title: "Report",
      defaultQueryParams: { type: "sidebar", showViewLinks: true, showActions: false },
    }),
    layoutTable: new RouteInfo<[string]>(AppRoutes.frameworkLayoutsRoot, "table", {
      title: "Table",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutUtilities: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "layout-utilities/:?view", {
      title: "Utilities",
    }),
  };
  // #endregion

  // #region External Links
  // #endregion
}

export type RouteInfoData<PathParams extends Primitives[] = [], QueryParams extends {} = {}> = FdRouteInfoData<
  UserRoles,
  PathParams,
  QueryParams
>;

export type PathParamsForRoute<T> = T extends RouteInfo<infer PathParams> ? PathParams : never;

export type QueryParamsForRoute<T> = T extends RouteInfo<never, infer QueryParams> ? QueryParams : never;
