import { WeekDay } from "@angular/common";

declare global {
  interface Date {
    fdGetFirstDayOfWeek(): Date;
    fdGetDayOfWeek(day: number): Date;
    fdAdjustDayValue(days: number, excludeSunday?: boolean): Date;
    fdAddMinutes(minutes: number): Date;
    fdGetDateOnly(): Date;
    fdGetTimeParts(date1: Date): {
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
      milliseconds: number;
    };
    fdGetTimeSpan(date1: Date): string;
  }
}

Date.prototype.fdGetFirstDayOfWeek = function () {
  return this.fdGetDayOfWeek(WeekDay.Sunday);
};

Date.prototype.fdGetDayOfWeek = function (day: WeekDay) {
  return new Date(this).fdAdjustDayValue(-1 * this.getDay() + day);
};

Date.prototype.fdAdjustDayValue = function (days, excludeSunday = false) {
  let newDate = new Date(this.setDate(this.getDate() + days));
  if (excludeSunday) {
    if (newDate.getDay() == 0 && days < 0) {
      newDate = new Date(this.setDate(this.getDate() - 1));
    }

    if (newDate.getDay() == 0 && days > 0) {
      newDate = new Date(this.setDate(this.getDate() + 1));
    }
  }
  return newDate;
};

Date.prototype.fdAddMinutes = function (minutes: number) {
  return new Date(this.getTime() + minutes * 60000);
};

Date.prototype.fdGetDateOnly = function () {
  const self: Date = this;
  return new Date(self.getFullYear(), self.getMonth(), self.getDate());
};

Date.prototype.fdGetTimeParts = function (date: Date) {
  const diffMilliseconds = this.getTime() - date.getTime();
  const days = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffMilliseconds / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diffMilliseconds / (1000 * 60)) % 60);
  const seconds = Math.floor((diffMilliseconds / 1000) % 60);
  const milliseconds = diffMilliseconds % 1000;
  return { days, hours, minutes, seconds, milliseconds };
};

Date.prototype.fdGetTimeSpan = function (date: Date) {
  const { days, hours, minutes, seconds } = this.fdGetTimeParts(date);
  const format = (num: number) => String(Math.abs(num)).padStart(2, "0");
  return `${days}.${format(hours)}:${format(minutes)}:${format(seconds)}`;
};
